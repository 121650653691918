import ChatBotMain from 'app/chat-bot'
import { HtmlTagWrapper } from 'chat-bot-html-tag-wrapper'
import Axios from 'axios'
const Settings = require('settings.json')

//make it dynamic
const currentProcess = process.env.REACT_APP_MODE
  ? process.env.REACT_APP_MODE
  : process.env.NODE_ENV

Axios.defaults.baseURL = Settings[currentProcess].server_url

const WrappedEleoChatBotWidget = HtmlTagWrapper(ChatBotMain)

export default {
  EleoChatBotWidget: WrappedEleoChatBotWidget,
}
